<template>
  <router-link :to="link" class="card card-lg display--flex">
    <div class="card-header">
      <div class="card-title">{{ title }}</div>
      <div class="card-label">{{ label }}</div>
      <img :src="require(`@/assets/images/svg/${imagePath}`)" alt="" />
    </div>
    <div class="card-footer">
      <p>
        {{ description }}
      </p>
      <router-link :to="link" class="btn btn-o btn-sm"
        >Все продукты</router-link
      >
    </div>
  </router-link>
</template>

<script>
export default {
  name: "product-card-lg",
  props: {
    title: String,
    label: String,
    imagePath: String,
    description: String,
    link: String,
  },
};
</script>

<style>

 a.card-lg:active, /* активная/посещенная ссылка */
 a.card-lg:hover,  /* при наведении */
 a.card-lg {
  text-decoration: none;
  color: var(--color);
}
.card-lg {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 35px;
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in;
}

.card-lg:hover {
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.card-lg .card-header {
  position: relative;
  width: 100%;
  min-height: 150px;
  margin-bottom: 35px;
}

.card-lg .card-title {
  max-width: calc(100% - 170px);
  margin-bottom: 26px;
  font-size: 36px;
  line-height: 36px;
}

.card-lg a.card-title {
  color: var(--color);
  text-decoration: none;
}

.card-lg a.card-title:hover {
  color: var(--accent);
}

.card-lg .card-label {
  display: inline-block;
  max-width: calc(100% - 170px);
  padding: 4px 8px 5px;
  background-color: var(--color-light);
  border-radius: 20px;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
}

.card-lg img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 150px;
  height: 150px;
  max-height: 150px;
}

.card-lg .card-footer p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

.card-lg .card-footer .btn {
  margin-top: 35px;
}

@media (min-width: 768px) and (max-width: 1100px) {
  .card-lg img {
    display: none !important;
  }

  .card-lg .card-title,
  .card-lg .card-label {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .card-lg {
    padding: 15px;
  }

  .card-lg .card-header {
    min-height: auto;
    margin-bottom: 30px;
  }

  .card-lg img {
    max-width: 60px;
    height: 60px;
    max-height: 60px;
  }

  .card-lg .card-title {
    max-width: calc(100% - 60px);
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }

  .card-lg .card-label {
    max-width: 100%;
  }

  .card-lg .card-footer p {
    font-size: 16px;
    line-height: 19px;
  }

  .card-lg .card-footer .btn {
    margin-top: 16px;
  }
}
</style>